<template>
  <div class="animated fadeIn">
    <b-card no-body>
      <b-container fluid class="pb-3">
        <b-row>
          <b-col>
            <TableWrapper
              :ref="model.name"
              :model="model"
              parent-id="anyValueToPassToTableWrapperToAutoLoadData"
              actions-mode="inline"
              :columns="tableColumns"
              :read-only="false"
              :filter-by-column="false"
              @inserted="onTableInsert"
              @updated="onTableChange"
            >
            </TableWrapper>
          </b-col>
        </b-row>
        <b-row style="margin-bottom: 10em;"> </b-row>
      </b-container>
    </b-card>
  </div>
</template>

<script>
import models from '@/models'
import TableWrapper from '@/components/DataView/TableWrapper'
import mileageRateService from '../../services/Reimbursements/mileage-rate.service'

export default {
  props: {
    id: {
      type: [String, Number],
      default: ''
    },
    mode: {
      type: Number,
      default: 0
    },
    active: {
      type: [Number, Boolean],
      default: 0
    }
  },
  name: 'MileageRates',
  components: { TableWrapper },
  data: function () {
    return {
      isLoading: false,
      saveInProgress: false,
      saveAndViewInProgress: false,
      model: models.reimbursements.mileageRate,
      //filters: ["Date", "Category"],
      tableColumns: ['ID', 'Date', 'Rate', 'Description'],
      data: {}
    }
  },
  created () {
    console.log('milageRates.created')
  },
  async mounted () {
    console.log('milageRates.mounted')
  },
  methods: {
    onTableInsert () {},
    onTableChange () {}
  },
  watch: {}
}
</script>

<style scoped>
/*
::v-deep .flex-row {
  width: 100%;
}*/
::v-deep .w-short {
  width: 30em !important;
}

::v-deep .VueTables__table {
  overflow: visible !important;
}
::v-deep .table-responsive {
  overflow: visible !important;
}
</style>
